var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.matches
    ? _c(
        "div",
        { staticClass: "sports-scores-wrapper" },
        [
          _vm.whatToShow === "team" && _vm.currentTeamId !== _vm.teamId
            ? _c(
                "p",
                {
                  staticClass: "back-to-original",
                  on: {
                    click: function ($event) {
                      return _vm.fetchTeamScores(_vm.teamId)
                    },
                  },
                },
                [_vm._v(" ⇦ Back to Original Team ")]
              )
            : _vm.whatToShow === "league" &&
              _vm.leagueId &&
              _vm.currentLeagueId !== _vm.leagueId
            ? _c(
                "p",
                {
                  staticClass: "back-to-original",
                  on: {
                    click: function ($event) {
                      return _vm.fetchLeagueScores(_vm.leagueId)
                    },
                  },
                },
                [_vm._v(" ⇦ Back to Original League ")]
              )
            : _vm._e(),
          _c("div", { staticClass: "past-or-future" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.tooltip("View Recent Scores"),
                    expression: "tooltip('View Recent Scores')",
                  },
                ],
                class: `btn ${_vm.whenToShow === "past" ? "selected" : ""}`,
                on: {
                  click: function ($event) {
                    return _vm.fetchPastFutureEvents("past")
                  },
                },
              },
              [_vm._v(" Past Scores ")]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.tooltip("View Upcoming Games"),
                    expression: "tooltip('View Upcoming Games')",
                  },
                ],
                class: `btn ${_vm.whenToShow === "future" ? "selected" : ""}`,
                on: {
                  click: function ($event) {
                    return _vm.fetchPastFutureEvents("future")
                  },
                },
              },
              [_vm._v(" Upcoming Games ")]
            ),
          ]),
          _vm._l(_vm.matches, function (match) {
            return _c("div", { key: match.id, staticClass: "match-row" }, [
              !_vm.hideImage
                ? _c("div", { staticClass: "match-thumbnail-wrap" }, [
                    _c("img", {
                      staticClass: "match-thumbnail",
                      attrs: {
                        src: match.thumbnail,
                        alt: `${match.title} Banner Image`,
                      },
                    }),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "score" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.tooltip(
                          `Click to view ${match.home.name} Scores`
                        ),
                        expression:
                          "tooltip(`Click to view ${match.home.name} Scores`)",
                      },
                    ],
                    class: `score-block home ${
                      _vm.currentTeamId !== match.home.id ? "clickable" : ""
                    }`,
                    on: {
                      click: function ($event) {
                        return _vm.fetchTeamScores(match.home.id)
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "team-score" }, [
                      _vm._v(_vm._s(match.home.score)),
                    ]),
                    _c("p", { staticClass: "team-name" }, [
                      _vm._v(_vm._s(match.home.name)),
                    ]),
                    _c("p", { staticClass: "team-location" }, [_vm._v("Home")]),
                  ]
                ),
                _c("div", { staticClass: "colon" }, [
                  _vm._v(
                    _vm._s(match.home.score || match.away.score ? ":" : "v")
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.tooltip(
                          `Click to view ${match.away.name} Scores`
                        ),
                        expression:
                          "tooltip(`Click to view ${match.away.name} Scores`)",
                      },
                    ],
                    staticClass: "score-block away clickable",
                    on: {
                      click: function ($event) {
                        return _vm.fetchTeamScores(match.away.id)
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "team-score" }, [
                      _vm._v(_vm._s(match.away.score)),
                    ]),
                    _c("p", { staticClass: "team-name" }, [
                      _vm._v(_vm._s(match.away.name)),
                    ]),
                    _c("p", { staticClass: "team-location" }, [_vm._v("Away")]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "match-info" }, [
                _c("p", { staticClass: "status" }, [
                  _vm._v(_vm._s(match.status) + " "),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "league",
                    on: {
                      click: function ($event) {
                        return _vm.fetchLeagueScores(match.leagueId)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(match.league) +
                        ", " +
                        _vm._s(match.season) +
                        " "
                    ),
                  ]
                ),
                _c("p", [
                  _c("a", { attrs: { href: _vm._f("mapsUrl")(match.venue) } }, [
                    _vm._v(_vm._s(match.venue)),
                  ]),
                  _vm._v(
                    " on " +
                      _vm._s(_vm._f("formatDate")(match.date)) +
                      " (" +
                      _vm._s(_vm._f("formatTime")(match.time)) +
                      ")"
                  ),
                ]),
              ]),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }